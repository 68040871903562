//import logo from './logo.svg';
//import './App.css';
//import { ReactComponent } from '*.svg';
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useEffect, useState } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link, 
  useRouteMatch, 
  useParams,
  useLocation
} from "react-router-dom";
import ReactMarkdown from 'react-markdown'
import { Auth0Provider } from "@auth0/auth0-react";
import { useAuth0 } from "@auth0/auth0-react";
import { GoogleLogin } from 'react-google-login';

const api_url = "https://dev.api.e6e.io"

const axios = require('axios');
const apiClient = axios.create({
  xsrfCookieName: 'csrf-token', 
  xsrfHeaderName: 'X-CSRF-Token',
  withCredentials: true
})

const responseGoogle = (response) => {

  var id_token = response.getAuthResponse().id_token;

  apiClient.post(api_url + '/auth', {}, { headers: {
    'Authorization': id_token
  }})
        .then(
          (response) => {
          console.log("Google Response");      
        })
        .catch(function (error) {
          console.log(error);
        })
        .then(function () {
          // always executed
        })
      }


const LoginButton = () => {
  const { loginWithRedirect } = useAuth0();

  return <button className="btn-small btn-primary" onClick={() => loginWithRedirect()}>Log In</button>;
};

const LogoutButton = () => {
  const { logout } = useAuth0();

  return (
    <button className="btn-small btn-primary" onClick={() => logout({ returnTo: window.location.origin })}>
      Log Out
    </button>
  );
};


function App(props) {
const[csrf, setCSRF] = useState(null)


  useEffect(() => {

    if (csrf == null) {
      apiClient.post(api_url + '/csrf')
        .then(
          (response) => {
          setCSRF(response.data.csrf);      
        })
        .catch(function (error) {
          console.log(error);
        })
        .then(function () {
          // always executed
        })

        axios.defaults.xsrfCookieName = 'csrf-token';
        axios.defaults.xsrfHeaderName = 'X-CSRFToken';
      }
  }

  ), [];

 return (
    <Router>

    
        
      <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <div className="container-fluid">
          <Link className="navbar-brand" to="/">Forecasts</Link>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse " id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0 align-middle">
              <li className="nav-item">
                <Link className="nav-link active" aria-current="page" to="/">Home</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link active" aria-current="page" to="/about">About</Link>
              </li>
              <li>  
               <GoogleLogin
                  clientId="1072494107196-occu5q0sr881p8kenk724nvpof9hg980.apps.googleusercontent.com"
                  buttonText="Login"
                  onSuccess={responseGoogle}
                  onFailure={responseGoogle}
                  cookiePolicy={'single_host_origin'}
                />
              </li>
              <li className="nav-item">
                <LoginButton></LoginButton>
              </li>
              <li className="nav-item">
                <LogoutButton></LogoutButton>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <div className="container-fluid">
        <div className="row flex-xl-wrap">
          <div className="col-12 col-md-6">
            <ViewAll></ViewAll>
          </div>
          <div className="col-12 col-md-6">
            <Switch>
              <Route path="/create">
              <div className="form-group container border p-3">
                 <p>Create box.</p>
                 <Viewer />
                </div>     
              </Route>
              <Route path="/view">
                    <Viewer />
              </Route>
              <Route path="/about" >
                <div className="form-group container border p-3">
                 <p>An about page for e6e</p>
                </div>
              </Route>
              <Route path="/" >
                <div className="form-group container border p-3">
                 <p>Select a scenario.</p>
                </div>
              </Route>

            </Switch>

          </div>
          

        </div>
      </div>
    </Router>
  );

}

export default App;




function ViewAll (props) {
  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();

  const [responseData, setResponseData] = useState(null);



  useEffect(() => {

    const fetchScenarios = async () => {
      const domain = "e6e.us.auth0.com";
  
      try {

 
        apiClient.get(api_url + '/api/question', { headers: {
          //'Authorization': `Bearer ${accessToken}` 
        }}, )
        .then(
          (response) => {
          console.log(response.data);
          setResponseData(response.data);      
        })
        .catch(function (error) {
          console.log(error);
        })
        .then(function () {
          // always executed
        }); 
  
        
      } catch (e) {
        console.log(e.message);
      }
    };

    fetchScenarios();


  }, []);


  
  if(responseData == null) {
    return (        
    <>
      <div className="container border p-3">
        <div className="card">
          Loading scenarios...
      </div>
      </div>
    </>)
  } else {

      const listScenarios = responseData.map((scenario) => <ScenarioChoice title={scenario.title} key={scenario.id} id={scenario.id} locked={scenario.locked}></ScenarioChoice>);

      return (

        
        <>
          <div className="container border p-3">
            <div className="card">
              <ul className="list-group list-group-flush">
                <li className="list-group-item">
                  <Link to="/create">
                    <button type="button" className="btn btn-primary">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-plus-circle" viewBox="0 0 16 16">
                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                        <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                      </svg>
                      
                      &nbsp;Create New!
                    </button>                  
                  </Link>
                </li>
                {listScenarios}
              </ul>
          </div>
          </div>
        </>
      );
  }
  
}

function ScenarioChoice(props) {
  const [locked, setLocked] = useState(props.locked);
  const [tags, setTags] = useState(null);
  const [title, setTitle] = useState(props.title)

    return(
    <li className="list-group-item"> 
      {locked 
        ? 
        
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-lock-fill" viewBox="0 0 16 16">
            <path d="M8 1a2 2 0 0 1 2 2v4H6V3a2 2 0 0 1 2-2zm3 6V3a3 3 0 0 0-6 0v4a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2z" />
          </svg>
        
        : 
        
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-unlock" viewBox="0 0 16 16">
            <path d="M11 1a2 2 0 0 0-2 2v4a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V9a2 2 0 0 1 2-2h5V3a3 3 0 0 1 6 0v4a.5.5 0 0 1-1 0V3a2 2 0 0 0-2-2zM3 8a1 1 0 0 0-1 1v5a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V9a1 1 0 0 0-1-1H3z"/>
          </svg>
        
      }
      <Link to={`/view/${props.id}`} > {title}</Link>
     </li>
    );
}



function Viewer(props) {

  let match = useRouteMatch();

  return (
      <>
      <Switch>
        <Route path="/create" >
          <ViewScenario create />
        </Route>
        <Route path={`${match.path}/:viewId`} >
          <ViewScenario />
        </Route>
        <Route path={match.path}>
          <div className="form-group container border p-3">
            No Scenario selected.
          </div>
        </Route>
        </Switch>
      </>
    );  
}

function ViewScenario(props) {

  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();

  const [title, setTitle] = useState("Loading...");
  //const [id, setId] = useState(props.id);
  const [optionsText, setOptionsText] = useState(["Yes","No"]);
  const [optionsValue, setOptionsValue] = useState([0,0]);
  const [description, setDescription] = useState("Loading...");
  const [type, setType] = useState("new");
  const [locked, setLocked] = useState(false);
  const [stats, setStats] = useState(false);
  const [isOwner, setIsOwner] = useState(false);
  const [canEdit, setCanEdit] = useState(false);
  const location = useLocation();

  let { viewId } = useParams();

  useEffect(() => {

    const fetchScenario = async () => {
 
      try {
 
        apiClient.get(api_url + '/api/question/' + viewId, {headers: {
          //'Authorization': `Bearer ${accessToken}` 
        }})
        .then(
          (response) => {
          console.log(response.data);
          setTitle(response.data.title);
          setOptionsText(response.data.optionsText);
          setOptionsValue(response.data.optionsValue);
          setDescription(response.data.description);
        })
        .catch(function (error) {
          console.log(error);
        })
        .then(function () {
          // always executed
        }); 
    
        
      } catch (e) {
        console.log(e.message);
      }
    };

    if(!props.create) {
     fetchScenario();
    }

  }, [location]);

  const handleTitleChange = (event) => {
    setTitle(event.target.value);
  }

  const addOption = (event) => {
    var new_optionsText = [...optionsText];
    var new_optionsValue = [...optionsValue];
    new_optionsText.push("");
    new_optionsValue.push(0);
    setOptionsText(new_optionsText)
    setOptionsValue(new_optionsValue)
  }

  const handleOptionValueChange = (event, index) => {
    var new_optionsValue = [...optionsValue];
    new_optionsValue[parseInt(index)] = parseInt(event.target.value);
    setOptionsValue(new_optionsValue)
  }

  const handleOptionTextChange = (event, index) => {
    var new_optionsText = [...optionsText];
    new_optionsText[parseInt(index)] = event.target.value;
    setOptionsText(new_optionsText)
  }

  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  }

  const deleteScenario = async () => {
    
    try {


      apiClient.delete('https://ayi4zhf22j.execute-api.us-west-1.amazonaws.com/api/question/' + viewId + '/delete', {headers: {
        //'Authorization': `Bearer ${accessToken}` 
      }})
      .then(
        (response) => {
        console.log(response.data);

      })
      .catch(function (error) {
        console.log(error);
      })
      .then(function () {
        // always executed
      }); 
  
      
    } catch (e) {
      console.log(e.message);
    }
  };

  const concludeScenario = async () => {


    try {
      const accessToken = await getAccessTokenSilently({
        audience: `http://localhost/auth`,
        scope: "read:current_user",
      });

      apiClient.delete('https://ayi4zhf22j.execute-api.us-west-1.amazonaws.com/api/question/' +viewId + '/conclude', {headers: {
        //'Authorization': `Bearer ${accessToken}` 
      }})
      .then(
        (response) => {
        console.log(response.data);

      })
      .catch(function (error) {
        console.log(error);
      })
      .then(function () {
        // always executed
      }); 
  
      
    } catch (e) {
      console.log(e.message);
    }
  };

  const removeOption = (event, index) => {
    if (optionsValue.length > 2) {
    var new_optionsText = [...optionsText];
    var new_optionsValue = [...optionsValue];
    new_optionsText.splice(index, 1)
    new_optionsValue.splice(index, 1)
    setOptionsText(new_optionsText)
    setOptionsValue(new_optionsValue)
    } else {
      console.log("Must have two options.")
    }
  }

  const putScenario = async () => {
    const domain = "e6e.us.auth0.com";

    try {

      apiClient.put('https://ayi4zhf22j.execute-api.us-west-1.amazonaws.com/api/question', {
        title: title,
        description: description,
        optionsValue: optionsValue,
        optionsText: optionsText,  
        locked: locked
      }, {headers: {
        //'Authorization': `Bearer ${accessToken}` 
      }})
      .then(
        (response) => {
        console.log(response.data);
      })
      .catch(function (error) {
        console.log(error);
      })
      .then(function () {
        // always executed
      }); 
  
      
    } catch (e) {
      console.log(e.message);
    }
  };

  return (
    <div className="form-group container border p-3">
    <form>
    <div className="form-group">
      {props.create ?
        <>
          <div className="form-row">
            <input type="text" className="form-control" onChange={handleTitleChange} placeholder="Enter scenario name." />
            <textarea className="form-control" onChange={handleDescriptionChange} placeholder="Enter scenario description." />
          </div>
        </>
        :
        <>
          {!canEdit ?
            <div className="card">
              <div className="card-body">
                <h2 className="font-weight-light">{title}</h2>
              </div>
            </div>
            :
            <input type="text" className="form-control" onChange={handleTitleChange} value={title} readOnly={!canEdit}/>
          }

          {!canEdit ?
          <div className="card">
            <div className="card-body">
              <ReactMarkdown>{description}</ReactMarkdown>
            </div>
          </div>
          :
           <textarea className="form-control" onChange={handleDescriptionChange} value={description} readOnly={!canEdit}/>
          }
        </>
      } 
      </div>

      <div className="form-group">
      {props.create ?
          
         optionsText.map((option, index) => (
          <div key={index} className="input-group">  
            {index > 1 ? 
               <div className="input-group-prepend">
                 <div className="input-group-text small" onClick={(event) => removeOption(event, index)}>Delete</div>
               </div> : <></>
            }

              <input type="text" className="form-control" onChange={(event) => handleOptionTextChange(event, index)} value={option}  />

            </div>
         ))
         :
         optionsText.map((option, index) => (
            <div key={index} className="form-row">              
              

              {!stats ?
                <>
                  <input type="text" className="form-control col-1" onChange={(event) => handleOptionValueChange(event, index)} value={optionsValue[index]} />
                  <input type="text" className="form-control col" onChange={(event) => handleOptionTextChange(event, index)} value={option} readOnly={!canEdit} />
                </>
              :
                <>
                <span className="input-group-text" >
                   <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-soundwave" viewBox="0 0 16 16">
                     <path fillRule="evenodd" d="M8.5 2a.5.5 0 0 1 .5.5v11a.5.5 0 0 1-1 0v-11a.5.5 0 0 1 .5-.5zm-2 2a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zm4 0a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zm-6 1.5A.5.5 0 0 1 5 6v4a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm8 0a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm-10 1A.5.5 0 0 1 3 7v2a.5.5 0 0 1-1 0V7a.5.5 0 0 1 .5-.5zm12 0a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0V7a.5.5 0 0 1 .5-.5z" />
                   </svg>
                 </span><input type="text" className="form-control col-1 font-weight-light" value="High" />
                 <span className="input-group-text" >
                   <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-up-circle-fill" viewBox="0 0 16 16">
                     <path d="M16 8A8 8 0 1 0 0 8a8 8 0 0 0 16 0zm-7.5 3.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707V11.5z" />
                   </svg>
                 </span><input type="text" className="form-control col-1 font-weight-light" value="12" />
                 <span className="input-group-text" >
                   <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-people-fill" viewBox="0 0 16 16">
                     <path d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H7zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                     <path fillRule="evenodd" d="M5.216 14A2.238 2.238 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.325 6.325 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1h4.216z" />
                     <path d="M4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5z" />
                   </svg>
                 </span><input type="text" className="form-control col-1 font-weight-bold" value={Math.random()} />
                 <span className="input-group-text" >
                   <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-down-circle-fill" viewBox="0 0 16 16">
                     <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v5.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V4.5z" />
                   </svg>
                 </span><input type="text" className="form-control col-1 font-weight-light" value={Math.random()} />
                 <span className="input-group-text" >
                 <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-hash" viewBox="0 0 16 16">
                    <path d="M8.39 12.648a1.32 1.32 0 0 0-.015.18c0 .305.21.508.5.508.266 0 .492-.172.555-.477l.554-2.703h1.204c.421 0 .617-.234.617-.547 0-.312-.188-.53-.617-.53h-.985l.516-2.524h1.265c.43 0 .618-.227.618-.547 0-.313-.188-.524-.618-.524h-1.046l.476-2.304a1.06 1.06 0 0 0 .016-.164.51.51 0 0 0-.516-.516.54.54 0 0 0-.539.43l-.523 2.554H7.617l.477-2.304c.008-.04.015-.118.015-.164a.512.512 0 0 0-.523-.516.539.539 0 0 0-.531.43L6.53 5.484H5.414c-.43 0-.617.22-.617.532 0 .312.187.539.617.539h.906l-.515 2.523H4.609c-.421 0-.609.219-.609.531 0 .313.188.547.61.547h.976l-.516 2.492c-.008.04-.015.125-.015.18 0 .305.21.508.5.508.265 0 .492-.172.554-.477l.555-2.703h2.242l-.515 2.492zm-1-6.109h2.266l-.515 2.563H6.859l.532-2.563z"/>
                  </svg>
                 </span><input type="text" className="form-control col-1 font-weight-light" value={Math.floor(Math.random() * 100)} />
                </>
              }

              
            </div>
         ))
      }
      </div>



      {props.create ? // Is is a create form?
          <>
          <div className="form-group">
            <button type="button" className="btn btn-secondary btn-sm" onClick={addOption}>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-plus-square" viewBox="0 0 16 16">
                <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
                <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
              </svg> Add Option
            </button>
            </div>
            <button type="button" className="btn btn-primary" onClick={putScenario}>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-plus-square" viewBox="0 0 16 16">
                <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
                <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
              </svg> Add Scenario
            </button>
          </> : // No, not a create form.
            <>
              <div className="card">
                <div className="card-header">Records</div>
                <div className="card-body">
                  <ul>
                    <li>Record one.</li>
                  </ul>
                </div>
              </div>
              <div className="card">
                <div className="card-header">Forecast</div>
                <div className="card-body">
                  <button type="button" className="btn btn-success" onClick={() => { setStats(!stats) }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-bar-chart-fill" viewBox="0 0 16 16">
                      <path d="M1 11a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1v-3zm5-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V7zm5-5a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1V2z" />
                    </svg><span className="align-middle">&nbsp;Show Stats</span>
                  </button>
                  <button type="button" className="btn btn-success" onClick={() => {}}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check-square-fill" viewBox="0 0 16 16">
                        <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm10.03 4.97a.75.75 0 0 1 .011 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.75.75 0 0 1 1.08-.022z" />
                      </svg><span className="align-middle">&nbsp;Submit Forecast </span>
                  </button>
                </div>
              </div>
              {!isOwner ? //Is it the owner?
                <>
                  <div className="card">
                    <div className="card-header">Moderation tools</div>
                    <div className="card-body">
                    <button type="button" className="btn btn-info" onClick={() => {setCanEdit(!canEdit)}}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-pencil-square" viewBox="0 0 16 16">
                          <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456l-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                          <path fillRule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
                        </svg><span className="align-middle">&nbsp;Edit</span>
                    </button>
                    <button type="button" className="btn btn-light">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-record-btn text-danger" viewBox="0 0 16 16">
                        <path d="M8 11a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
                        <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4zm15 0a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4z"/>
                      </svg><span className="align-middle">&nbsp;Record</span>
                    </button>
                      {locked ?
                      <button type="button" className="btn btn-primary">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-lock" viewBox="0 0 16 16">
                          <path d="M8 1a2 2 0 0 1 2 2v4H6V3a2 2 0 0 1 2-2zm3 6V3a3 3 0 0 0-6 0v4a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2zM5 8h6a1 1 0 0 1 1 1v5a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V9a1 1 0 0 1 1-1z" />
                        </svg><span className="align-middle">&nbsp;Record</span>
                      </button>
                      :
                      <button type="button" className="btn btn-secondary" onClick={() => {if(window.confirm("Lock this scenario? (Cannot be undone)")) {setLocked(!locked); putScenario()}}}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-unlock" viewBox="0 0 16 16">
                          <path d="M11 1a2 2 0 0 0-2 2v4a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V9a2 2 0 0 1 2-2h5V3a3 3 0 0 1 6 0v4a.5.5 0 0 1-1 0V3a2 2 0 0 0-2-2zM3 8a1 1 0 0 0-1 1v5a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V9a1 1 0 0 0-1-1H3z"/>
                        </svg><span className="align-middle">&nbsp;Lock</span>
                      </button>
                      } 
                    <button type="button" className="btn btn-dark" onClick={() => { if(window.confirm("Do you want to conclude this scenario?")) concludeScenario() } }>
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check-square" viewBox="0 0 16 16">
                        <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
                        <path d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.235.235 0 0 1 .02-.022z" />
                      </svg><span className="align-middle">&nbsp;Conclude</span>
                    </button>
                    <button type="button" className="btn btn-danger" onClick={() => { if(window.confirm("Do you want to delete this scenario?")) deleteScenario() } }>
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="align-middle bi bi-x-square" viewBox="0 0 16 16">
                        <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                      </svg><span className="align-middle">&nbsp;Delete</span>
                    </button>
                    </div>
                  </div>
                </> 
                :
                <></>
            }
          </>
      }

    </form>
  </div>
  )
}